const pages = {
    home: {
        name: 'product',
        path: '/sidebar/product'
    },
    status: {
        root: 'status',
        statusComingSoon: {
            name: 'coming-soon',
            path: '/status/coming-soon'
        },
        statusMaintenance: {
            name: 'maintenance',
            path: '/status/maintenance'
        },
        status404: {
            name: '404',
            path: '/status/404'
        },
        status500: {
            name: '500',
            path: '/status/500'
        },
        loading: {
            name: 'loading',
            path: '/status/loading'
        },
    },
    input: {
        name: 'input',
        path: '/sidebar/input'
    },
    feedback: {
        name: 'feedback',
        path: '/sidebar/feedback'
    },
    about: {
        name: 'about',
        path: '/sidebar/about'
    },
    contact: {
        name: 'contact',
        path: '/sidebar/contact'
    },
    help: {
        name: 'help',
        path: '/sidebar/help'
    },
}

export default pages;
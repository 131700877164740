import { Box, useTheme } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';
import { selectTheme } from '../../store/theme/themeSlice';

interface ILogoInterface {
  height?: number | string;
  width?: number | string;
}

function Logo(props: ILogoInterface) {
  const { height = 'auto', width = 'auto' } = props;
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="flex-end" paddingLeft={25}>
      <img
        //src={theme.palette.mode === 'dark' ? '/static/images/brand/3bo Logo l.png' : '/static/images/brand/3bo Logo d.png'}
        src={theme.palette.mode === 'dark' ? '/static/images/brand/3bo_logo.png' : '/static/images/brand/3bo_logo.png'}
        alt="Logo"
        height={200} // Adjust the height to a smaller value
        width={200} // Adjust the width to a smaller value
        //style={{ margin: 'auto' }}
      />
    </Box>
  );
}

export default Logo;
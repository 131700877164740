import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, Typography, Hidden, Container, Button, Grid } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';

const GridWrapper = styled(Grid)(
  ({ theme }) => `
    background: ${theme.colors.gradients.black1};
`,
);

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: calc(100vh / 3); /* Move the content one-third up the page */
  `,
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.white[100]};
  `,
);

const TypographySecondary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.alpha.white[70]};
  `,
);


function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Loading</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md">
          <Grid xs={12} md={6} alignItems="center" display="flex" justifyContent="center" item>
            <Container maxWidth="sm">
              <Box textAlign="center">
                <img alt="500" height={260} src="/static/images/brand/3bo_logo_1.png" />
                <Typography variant="h1" sx={{ my: 2 }}>
                  LOADING
                </Typography>
                <Typography variant="h4" color="text.secondary" fontWeight="normal" sx={{ mb: 4 }}>
                  Please wait
                </Typography>
              </Box>
            </Container>
          </Grid>
        </Container>
      </MainContent>
    </>
  );
}

export default SuspenseLoader;

import { Suspense, lazy, FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import pages from './routes';

const Loader = (Component: React.FC<any>) => (props: any) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Product = Loader(lazy(() => import('src/pages/Product')));
const Input = Loader(lazy(() => import('src/pages/Input')));
const Feedback = Loader(lazy(() => import('src/pages/Feedback')));
const Tracker = Loader(lazy(() => import('src/pages/Tracker')));
const Contact = Loader(lazy(() => import('src/pages/Contact')));
const About = Loader(lazy(() => import('src/pages/About')));
const Help = Loader(lazy(() => import('src/pages/Help')));

// Status
const Status404 = Loader(lazy(() => import('src/components/Fallbacks/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/components/Fallbacks/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/components/Fallbacks/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/components/Fallbacks/Status/Maintenance')));
const StatusLoading = Loader(lazy(() => import('src/components/Fallbacks/Status/Loading')));

const routes: RouteObject[] = [
  {
    /**
     * This is the root path API_URL
     */
    path: '',
    /**
     * This is a basic container element without any Navbars or Sidebars
     */
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to={pages.home.path} replace />,
      },
      {
        path: '/home',
        element: <Navigate to={pages.home.path} replace />,
      },
      {
        path: pages.status.root,
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />,
          },
          {
            path: pages.status.status404.name,
            element: <Status404 />,
          },
          {
            path: pages.status.status500.name,
            element: <Status500 />,
          },
          {
            path: pages.status.statusMaintenance.name,
            element: <StatusMaintenance />,
          },
          {
            path: pages.status.statusComingSoon.name,
            element: <StatusComingSoon />,
          },
        ],
      },
      {
        path: pages.status.loading.name, // Update the path for the loading page
        element: <StatusLoading />, // Add the loading page component
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: 'sidebar/:id',
    element: <SidebarLayout />,
    children: [
      {
        path: 'tracker',
        element: <Tracker />,
      },
      // Other child routes for sidebar/:id

      // Outlet for handling invalid paths
      {
        path: '*', // Wildcard route for invalid paths
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Status404 />,
          },
        ],
      },
    ],
  },
  {
    path: 'sidebar',
    element: <SidebarLayout />,
    children: [
      {
        path: 'product',
        element: <Product />,
      },
      {
        path: 'feedback',
        element: <Feedback />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
      {
        path: 'user_manual',
        element: <Help />,
      },
      // ... other child routes for sidebar

      // Outlet for handling invalid paths
      {
        path: '*', // Wildcard route for invalid paths
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Status404 />,
          },
        ],
      },
    ],
  },
];


export default routes;
